
import Vue from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
@Component({
    components: {},
    filters: {
        //局部过滤器
    },
})
export default class ClassComponent extends Vue {
    //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
    // @Prop()
    //计算属性
    // get computedMsg(): string {
    //     return ''
    // }
    toIndex() {
        this.$router.push({
            name: 'VIEW_MY_DOCUMENT',
        })
    }
    //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
    // @Watch('')
    //ref 引用元素 Ref('ref')
    // @Ref()
    //创建前钩子函数
    // created() {}
}
